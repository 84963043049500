import { enqueueSnackbar } from 'notistack';

import { createSendTokenTransaction } from './utils/createSendTokenTransaction';
import { utils } from '../utils';

import type { IReqTxBuildTokenSend } from '../types';

export const txBuildTokenSend = async (
  request: IReqTxBuildTokenSend,
): Promise<string> => {
  try {
    const txb = await createSendTokenTransaction(
      request.address,
      request.token.to,
      request.token.info.type,
      request.token.info.decimal
        ? utils.parseUnit(request.token.amount, request.token.info.decimal)
        : request.token.amount, // TODO
    );
    return txb.serialize();
  } catch (error) {
    enqueueSnackbar(`${error}`, {
      variant: 'error',
    });
    throw new Error(`not support provider (${request.network})`);
  }
};
