import { Box } from '@mui/material';

import { utils } from '../provider/zktx/utils';

import type { NETWORK } from '../provider/zktx/types';

export const DrawChain = ({
  network,
  size,
}: {
  network: NETWORK;
  size: number;
}) => {
  return (
    <>
      {utils.getChainName(network) === 'aptos' && (
        <Box
          component="img"
          draggable="false"
          sx={{
            height: size,
            width: size,
          }}
          alt="APTOS"
          src="/logo-aptos.svg"
        />
      )}
      {utils.getChainName(network) === 'sui' && (
        <Box
          component="img"
          draggable="false"
          sx={{
            height: size,
            width: size,
          }}
          alt="SUI"
          src="/logo-sui.svg"
        />
      )}
    </>
  );
};
