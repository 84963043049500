import { genAddressSeed, getZkLoginSignature } from '@mysten/zklogin';
import { decodeJwt } from 'jose';
import { enqueueSnackbar } from 'notistack';

export const getZkSignature = (
  salt: string,
  maxEpoch: number,
  proof: string,
  jwt: string,
  userSignature: string,
): string | undefined => {
  const decodedJwt = decodeJwt(jwt);

  const addressSeed =
    decodedJwt &&
    decodedJwt.sub &&
    decodedJwt.aud &&
    genAddressSeed(
      BigInt(salt),
      'sub',
      decodedJwt.sub,
      decodedJwt.aud as string,
    ).toString();

  if (!addressSeed) {
    enqueueSnackbar('jwt decode error', {
      variant: 'error',
    });
    throw new Error(`jwt decode error (${decodedJwt?.sub})`);
  }

  const zkLoginSignature = getZkLoginSignature({
    inputs: {
      ...JSON.parse(proof),
      addressSeed,
    },
    maxEpoch,
    userSignature,
  });

  return zkLoginSignature;
};
