import { TransactionBlock } from '@mysten/sui.js/transactions';
import { blake2b } from 'hash-wasm';
import { sign as naclSign } from 'tweetnacl';

import { getZkSignature } from './utils/getZkSignature';

import type { IReqTxSign, IResTxSign } from '../types';
import type { SuiClient } from '@mysten/sui.js/dist/cjs/client';

const SIGNATURE_SCHEME_TO_FLAG = {
  ED25519: 0x00,
  Secp256k1: 0x01,
};

export const txSign = async (request: IReqTxSign): Promise<IResTxSign> => {
  try {
    if (request.privateKey && request.proof) {
      const intent = [0, 0, 0]; // IntentScope (TransactionData), IntentVersion (v0), AppId (Sui)
      const txb = await TransactionBlock.from(request.unsignedTx).build({
        client: global._CLIENT as SuiClient,
      });
      const intentMessage = new Uint8Array(intent.length + txb.length);
      intentMessage.set(intent);
      intentMessage.set(txb, intent.length);
      const digest = await blake2b(intentMessage, 256);

      const { secretKey, publicKey } = naclSign.keyPair.fromSeed(
        Buffer.from(request.privateKey),
      );
      const signature = naclSign.detached(
        Buffer.from(digest, 'hex'),
        secretKey,
      );
      const temp = new Uint8Array(1 + signature.length + publicKey.length);
      temp.set([SIGNATURE_SCHEME_TO_FLAG.ED25519]);
      temp.set(signature, 1);
      temp.set(publicKey, 1 + signature.length);

      const zkLoginSignature = getZkSignature(
        request.salt,
        request.expiration,
        request.proof,
        request.jwt,
        Buffer.from(temp).toString('base64'),
      );

      return {
        unsignedTx: Buffer.from(txb).toString('base64'),
        signature: zkLoginSignature || '',
      };
    } else {
      throw new Error('keystore error');
    }
  } catch (error) {
    throw new Error(`${error}`);
  }
};
