import { useEffect, useRef } from 'react';

import { Container, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { AppAppBar } from '../component/appBar';
import { ListNfts } from '../component/listNfts';
import { Wallet } from '../component/wallet';
import { useProviderFS } from '../provider/file';
import { useProviderZktx } from '../provider/zktx';
import { accountState } from '../recoil';

import type { IAccount, IAccounts } from '../recoil';

export const Root = () => {
  const initialized = useRef<boolean>(false);
  const navigate = useNavigate();

  const fs = useProviderFS();
  const api = useProviderZktx();

  const setAccount = useSetRecoilState(accountState);

  useEffect(() => {
    const init = async () => {
      if (fs) {
        initialized.current = true;
        const hasConfig = await fs.isExist('config.json');
        if (hasConfig) {
          const config = await fs.readFile('config.json');
          const { selected } = JSON.parse(
            Buffer.from(config).toString('utf8'),
          ) as { selected: string };
          const list = await fs.readDir('/');
          const buffer = await Promise.all(
            list
              .filter((item) => item.split(':').length === 3) // CHAIN:NETWORK:ADDRESS
              .map((item) => fs.readFile(item)),
          );

          const accounts: IAccounts = { accounts: {} };
          const wallets = buffer.map(
            (item) =>
              JSON.parse(Buffer.from(item).toString('utf8')) as IAccount,
          );
          wallets.forEach((item) => {
            if (
              `${item.nonce.network}:${item.zkAddress.address}` === selected
            ) {
              accounts.selected = item;
            }
            accounts.accounts = {
              ...accounts.accounts,
              [`${item.nonce.network}:${item.zkAddress.address}`]: item,
            };
          });

          if (accounts.selected) {
            api.wallet.setClient(accounts.selected.nonce.network);
            setAccount(accounts);
            return;
          }
        }
        navigate('/provider');
      }
    };
    !initialized.current && init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fs]);

  return (
    <div className="App">
      <header className="App-header"></header>
      <AppAppBar />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} width="100%">
          <Wallet />
          <ListNfts />
        </Stack>
      </Container>
    </div>
  );
};
