import type { IReqNftList, IResNftList } from '../types';

export const getNftList = async (
  request: IReqNftList,
): Promise<IResNftList> => {
  try {
    return {
      list: [],
      nextPage: undefined,
    };
  } catch (error) {
    throw new Error(`${error}`);
  }
};
