import type { NETWORK } from '../../types';

export const getProverUrl = (network: NETWORK): string => {
  switch (network) {
    case 'sui:mainnet':
    case 'sui:testnet':
      return 'https://prover.mystenlabs.com/v1';
    case 'sui:devnet':
      return 'https://prover-dev.mystenlabs.com/v1';
    default:
      break;
  }
  throw new Error('not support network');
};
