import { jwtToAddress } from '@mysten/zklogin';
import { decodeJwt } from 'jose';

import { getEnokiSalt } from './utils/getEnokiSalt';
import { getZkProof } from './utils/getZkProof';

import type { IAccount } from '../../../recoil';
import type { IReqMakeZkProof } from '../types';

export const makeZkProof = async ({
  nonce,
  jwt,
  salt,
}: IReqMakeZkProof): Promise<IAccount> => {
  const { email, picture } = decodeJwt(jwt) as {
    email: string;
    picture: string;
  };

  if (nonce.network === 'sui:devnet') {
    const address = jwtToAddress(jwt, BigInt(salt));
    const proof = await getZkProof({ nonce, jwt, salt });
    return {
      email,
      picture,
      nonce,
      zkAddress: {
        address,
        jwt,
        salt,
        proof,
      },
    };
  }

  const enoki = await getEnokiSalt(jwt);
  const proof = await getZkProof({ nonce, jwt, salt: enoki.salt });
  return {
    email,
    picture,
    nonce,
    zkAddress: {
      address: enoki.address,
      jwt,
      salt: enoki.salt,
      proof,
    },
  };
};
