import { makeZkProof as aptos } from './aptos/makeZkProof';
import { makeZkProof as sui } from './sui/makeZkProof';

import type { IReqMakeZkProof } from './types';
import type { IAccount } from '../../recoil';

export const makeZkProof = async (
  request: IReqMakeZkProof,
): Promise<IAccount> => {
  switch (request.nonce.network) {
    case 'aptos:mainnet':
    case 'aptos:devnet':
    case 'aptos:testnet':
      return aptos(request);
    case 'sui:mainnet':
    case 'sui:devnet':
    case 'sui:testnet':
      return sui(request);
    default:
      break;
  }
  throw new Error('not support network');
};
