import { serializeTxAptos } from './utils/serializeTxAptos';
import { serializeTxSui } from './utils/serializeTxSui';

import type { NETWORK } from '../types';
import type { UnsignedTransaction } from '@wormhole-foundation/sdk';

export const serializeTx = async (
  network: NETWORK,
  address: string,
  txs: AsyncGenerator<UnsignedTransaction>,
): Promise<string> => {
  switch (network) {
    case 'aptos:mainnet':
    case 'aptos:testnet':
    case 'aptos:devnet':
      return serializeTxAptos(address, txs);
    case 'sui:mainnet':
    case 'sui:testnet':
    case 'sui:devnet':
      return serializeTxSui(address, txs);
    default:
      break;
  }
  throw new Error('serializeTx error');
};
