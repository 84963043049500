import { TransactionBlock } from '@mysten/sui.js/transactions';

import CONFIG from '../../../config.json';

import type { SuiClient } from '@mysten/sui.js/dist/cjs/client';

export const createSendTokenTransaction = async (
  from: string,
  to: string,
  type: string,
  amount: string,
): Promise<TransactionBlock> => {
  const txb = new TransactionBlock();
  txb.setSender(from);
  if (type === CONFIG.sui.currentyType) {
    const [coin] = txb.splitCoins(txb.gas, [txb.pure(amount)]);
    txb.transferObjects([coin], to);
  } else {
    const { data } = await (global._CLIENT as SuiClient).getCoins({
      owner: from,
      coinType: type,
    });
    data.length > 1 &&
      txb.mergeCoins(
        data[0].coinObjectId,
        data.slice(1).map((item) => item.coinObjectId),
      );
    const [coin] = txb.splitCoins(data[0].coinObjectId, [amount]);
    txb.transferObjects([coin], to);
  }

  return txb;
};
