import { serializeTx } from './serializeTx';
import { getChainContext } from './utils/getChainContext';

import type { IReqWormholeRedeemTx } from '../types';
import type { Wormhole } from '@wormhole-foundation/sdk/dist/cjs';

export const txBuildTokenRedeem = async (
  wh: Wormhole<'Mainnet' | 'Testnet' | 'Devnet'>,
  req: IReqWormholeRedeemTx,
): Promise<string> => {
  const snd = getChainContext(wh, req.redeem.network);
  const [whm] = await snd.parseTransaction(req.redeem.txHash);
  const vaa = await wh.getVaa(whm!, 'TokenBridge:Transfer', 60_000);
  const rcv = getChainContext(wh, req.network);
  const rcvTb = await rcv.getTokenBridge();
  const redeem = rcvTb.redeem(req.address, vaa!);
  return serializeTx(req.network, req.address, redeem);
};
