import { Aptos, AptosConfig } from '@aptos-labs/ts-sdk';

import { getNetworkConfig } from './utils/getNetworkConfig';

import type { NETWORK } from '../types';

export const setClient = (network: NETWORK): void => {
  const aptosConfig = new AptosConfig({
    network: getNetworkConfig(network),
  });
  const aptos = new Aptos(aptosConfig);
  global._CLIENT = aptos;
};
