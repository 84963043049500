import CONFIG from '../../config.json';
import { utils } from '../utils';

import type { IReqGetBalance, ITokenInfo } from '../types';
import type { Aptos } from '@aptos-labs/ts-sdk';

export const getBalance = async (
  request: IReqGetBalance,
): Promise<ITokenInfo[]> => {
  try {
    const balance: ITokenInfo[] = [];
    const amount = await (global._CLIENT as Aptos).getAccountAPTAmount({
      accountAddress: request.address,
    });
    const value = amount.toString();
    balance.push({
      name: 'APT',
      symbol: 'APT',
      type: CONFIG.aptos.currentyType,
      decimal: CONFIG.aptos.decimal,
      value,
      fValue: utils.formatUnit(value, CONFIG.aptos.decimal),
    });
    // TODO: get other tokens
    return balance;
  } catch (error) {
    const value = '0';
    return [
      {
        name: 'APT',
        symbol: 'APT',
        type: CONFIG.aptos.currentyType,
        value,
        fValue: utils.formatUnit(value, CONFIG.aptos.decimal),
      },
    ];
  }
};
