import { useState } from 'react';

import { Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export const DlgPassword = ({
  open,
  handleConfirm,
}: {
  open: boolean;
  handleConfirm: (password: string | undefined) => Promise<void>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => handleConfirm(undefined)}
    >
      <DialogTitle>Password</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <TextField
            label="Password"
            variant="standard"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleConfirm(undefined)} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            try {
              setLoading(true);
              await handleConfirm(password);
            } finally {
              setLoading(false);
            }
          }}
          disabled={loading}
          variant="contained"
          color="secondary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
