import { keccak_256 } from '@noble/hashes/sha3'; // TODO: REMOVE

import type { CHAIN, NETWORK } from './types';
export const ZKPATH_PREFIX = 'zkpath';

export const utils = {
  hex2buffer: (hex: string): Buffer => {
    return Buffer.from(hex.replace('0x', ''), 'hex');
  },
  base2buffer: (b64: string): Buffer => {
    return Buffer.from(b64, 'base64');
  },
  str2buffer: (str: string): Buffer => {
    return Buffer.from(str, 'utf-8');
  },
  str2Hash: (str: string, bytes: 8 | 16 | 32 = 32): string => {
    return `0x${Buffer.from(keccak_256(str))
      .toString('hex')
      .slice(0, bytes * 2)}`;
  },
  base2Hex: (b64: string): string => {
    return `0x${Buffer.from(b64, 'base64').toString('hex')}`;
  },
  formatUnit: (value: string, decimal: number): string => {
    const temp = `${'0'.repeat(decimal)}${value}`;
    const point = temp
      .slice(temp.length - decimal, temp.length)
      .replace(/0+/, '');
    const num = temp.slice(0, temp.length - decimal).replace(/^0+/, '');
    return `${num || '0'}.${point || '0'}`;
  },
  parseUnit: (value: string, decimal: number): string => {
    const num = value.split('.');
    if (num.length === 2) {
      return `${num[0]}${num[1].padEnd(decimal, '0').slice(0, decimal)}`;
    } else if (num.length === 1) {
      return value[0] !== '.'
        ? `${num[0]}${''.padEnd(decimal, '0').slice(0, decimal)}`
        : `${num[0].padEnd(decimal, '0').slice(0, decimal)}`;
    }
    return '';
  },
  getZkPath: (network: string, index: number): string => {
    return `${ZKPATH_PREFIX}:${network}:${index}`;
  },
  getChainName: (network: NETWORK): CHAIN => {
    return network.split(':')[0] as CHAIN;
  },
  getChainType: (network: NETWORK): 'mainnet' | 'testnet' | 'devnet' => {
    return network.split(':')[1] as 'mainnet' | 'testnet' | 'devnet';
  },
};
