import CONFIG from '../../../config.json';

interface IGetEnokiSalt {
  address: string;
  salt: string;
}

export const getEnokiSalt = async (jwt: string): Promise<IGetEnokiSalt> => {
  const res = await fetch(`${CONFIG.sui.enoki.url}/zklogin`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${CONFIG.sui.enoki.key}`,
      'zklogin-jwt': jwt,
    },
  });
  const { data } = (await res.json()) as { data: IGetEnokiSalt };
  return data;
};
