import CONFIG from '../../config.json';
import { utils } from '../utils';

import type { IReqGetBalance, ITokenInfo } from '../types';
import type { SuiClient } from '@mysten/sui.js/client';

export const getBalance = async (
  request: IReqGetBalance,
): Promise<ITokenInfo[]> => {
  try {
    const client = global._CLIENT as SuiClient;
    const res = await client.getAllBalances({
      owner: request.address,
    });

    let hasCurrency: boolean = false;
    const balances: ITokenInfo[] = res.map((item) => {
      if (item.coinType === CONFIG.sui.currentyType && !hasCurrency) {
        hasCurrency = true;
      }
      return item.coinType === CONFIG.sui.currentyType
        ? {
            name: 'SUI',
            symbol: 'SUI',
            type: item.coinType,
            decimal: CONFIG.sui.decimal,
            value: item.totalBalance,
            fValue: utils.formatUnit(item.totalBalance, CONFIG.sui.decimal),
            locked: item.lockedBalance,
          }
        : {
            name: 'Unknown Token', // TODO: find name
            symbol: 'N/A',
            type: item.coinType,
            value: item.totalBalance,
            fValue: item.totalBalance, // TODO: calc
            locked: item.lockedBalance,
          };
    });

    const info: ITokenInfo[] = [];
    !hasCurrency &&
      info.push({
        name: 'SUI',
        symbol: 'SUI',
        type: CONFIG.sui.currentyType,
        decimal: CONFIG.sui.decimal,
        value: '0',
        fValue: '0.0',
      });

    for (const item of balances) {
      if (item.type !== CONFIG.sui.currentyType) {
        const meta = await client.getCoinMetadata({ coinType: item.type });
        if (meta) {
          item.name = meta.name;
          item.symbol = meta.symbol;
          item.decimal = meta.decimals;
          item.fValue = utils.formatUnit(item.value, meta.decimals);
        }
      }
      info.push(item);
    }

    return info;
  } catch (error) {
    throw new Error(`${error}`);
  }
};
