import { Network } from '@aptos-labs/ts-sdk';

import type { NETWORK } from '../../types';

export const getNetworkConfig = (network: NETWORK): Network => {
  switch (network) {
    case 'aptos:mainnet':
      return Network.MAINNET;
    case 'aptos:devnet':
      return Network.DEVNET;
    case 'aptos:testnet':
      return Network.TESTNET;
    default:
      break;
  }
  throw new Error(`${network} is not supported`);
};
