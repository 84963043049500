import { useState } from 'react';

import { Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export const DlgSetPassword = ({
  open,
  handleConfirm,
}: {
  open: boolean;
  handleConfirm: (password: string | undefined) => Promise<void>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pass0, setPass0] = useState<string>('');
  const [pass1, setPass1] = useState<string>('');

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => handleConfirm(undefined)}
    >
      <DialogTitle>Set Password</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <TextField
            label="Password"
            variant="standard"
            type="password"
            onChange={(e) => {
              setPass0(e.target.value);
            }}
          />
          <TextField
            label="Password"
            variant="standard"
            type="password"
            onChange={(e) => {
              setPass1(e.target.value);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleConfirm(undefined)} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            if (pass0 && pass0 === pass1) {
              try {
                setLoading(true);
                await handleConfirm(pass0);
              } finally {
                setLoading(false);
              }
            }
          }}
          disabled={!pass0 || pass0 !== pass1 || loading}
          variant="contained"
          color="secondary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
