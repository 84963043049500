import { utils } from '../utils';

import type { IReqTxBuildTokenSend } from '../types';
import type { Aptos } from '@aptos-labs/ts-sdk';

export const txBuildTokenSend = async (
  request: IReqTxBuildTokenSend,
): Promise<string> => {
  const tx = await (global._CLIENT as Aptos).transferCoinTransaction({
    sender: request.address,
    recipient: request.token.to,
    coinType: request.token.info.type as any,
    amount: request.token.info.decimal
      ? parseInt(
          utils.parseUnit(request.token.amount, request.token.info.decimal),
        )
      : parseInt(request.token.amount), // TODO
  });
  return Buffer.from(tx.rawTransaction.bcsToBytes()).toString('base64');
};
