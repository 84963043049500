import { useState } from 'react';

import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { enqueueSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';

import CONFIG from '../provider/config.json';
import { useProviderKeyStore } from '../provider/keystore';
import { useProviderZktx } from '../provider/zktx';
import { utils } from '../provider/zktx/utils';
import { accountState } from '../recoil';

import type { CHAIN } from '../provider/zktx/types';

export const DlgWormholeTokenRcv = ({ sources }: { sources: CHAIN[] }) => {
  const api = useProviderZktx();
  const keyStore = useProviderKeyStore();
  const [{ selected: account }] = useRecoilState(accountState);

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [source, setSource] = useState<CHAIN>(sources[0]);
  const [txHash, setTxHash] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSend = async () => {
    try {
      if (
        account &&
        utils.getChainType(account.nonce.network) ===
          CONFIG.wormhole.network.toLowerCase()
      ) {
        setLoading(true);
        const unsignedTx = await api.wormhome.txBuildTokenRedeem({
          network: account.nonce.network,
          salt: account.zkAddress.salt,
          address: account.zkAddress.address,
          proof: account.zkAddress.proof,
          redeem: {
            network:
              `${source}:${CONFIG.wormhole.network.toLowerCase()}` as any, // TODO
            txHash,
          },
        });
        const privateKey = await keyStore.load(
          `${account.nonce.crypto}:${Buffer.from(account.nonce.publicKey, 'base64').toString('hex')}`, // base64 -> hex
          password,
        );
        const signedTx = await api.wallet.txSign({
          unsignedTx,
          network: account.nonce.network,
          salt: account.zkAddress.salt,
          address: account.zkAddress.address,
          privateKey,
          expiration: account.nonce.expiration,
          jwt: account.zkAddress.jwt,
          proof: account.zkAddress.proof,
        });
        if (signedTx.signature) {
          api.wallet.txSend({
            unsignedTx: signedTx.unsignedTx,
            network: account.nonce.network,
            salt: account.zkAddress.salt,
            address: account.zkAddress.address,
            signature: signedTx.signature,
          });
          setOpen(false);
        } else {
          enqueueSnackbar('sign transaction error', {
            variant: 'error',
          });
        }
      }
    } catch (error) {
      enqueueSnackbar(`${error}`, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Wormhole Redeem">
        <IconButton
          disabled={!account || !api.wormhome.isEnabled(account.nonce.network)}
          sx={{
            height: { xs: 24, sm: 32 },
            width: { xs: 24, sm: 32 },
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <Box
            component="img"
            draggable="false"
            sx={{
              height: { xs: 16, sm: 24 },
              width: { xs: 16, sm: 24 },
              opacity:
                !account || !api.wormhome.isEnabled(account.nonce.network)
                  ? 0.2
                  : 1,
            }}
            alt="WORMHOLE"
            src="/logo-wormhole.svg"
          />
        </IconButton>
      </Tooltip>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Wormhole (Redeem)</DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <TextField
              label="Source"
              select
              variant="standard"
              defaultValue={sources[0]}
              value={source}
              onChange={(e) => setSource(e.target.value as CHAIN)}
            >
              {sources.map((item, key) => (
                <MenuItem value={item} key={key}>
                  {item.toUpperCase()}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label={`Transaction Hash`}
              variant="standard"
              onChange={(e) => {
                setTxHash(e.target.value);
              }}
            />
            <TextField
              label="Password"
              variant="standard"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            disabled={loading || !password || !txHash}
            onClick={handleSend}
            variant="contained"
            color="secondary"
          >
            {loading ? <CircularProgress size={21} /> : 'Receive'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
