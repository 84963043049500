import { Ed25519PublicKey } from '@mysten/sui.js/keypairs/ed25519';
import {
  generateNonce,
  generateRandomness,
  getExtendedEphemeralPublicKey,
} from '@mysten/zklogin';

import CONFIG from '../../../config.json';
import { utils } from '../../utils';

import type { IReqMakeOAuthUrl } from '../../types';
import type { SuiClient } from '@mysten/sui.js/dist/cjs/client';

interface IGetNonce {
  nonce: string;
  randomness: string;
  maxEpoch: number;
}

export const getNonce = async (
  request: IReqMakeOAuthUrl,
): Promise<IGetNonce> => {
  const ephemeralPublicKey = new Ed25519PublicKey(
    utils.base2buffer(request.publicKey),
  );

  if (request.network === 'sui:devnet') {
    const { epoch } = await (
      global._CLIENT as SuiClient
    ).getLatestSuiSystemState();
    const maxEpoch = Number(epoch) + request.duration;

    const randomness = generateRandomness();

    const nonce = generateNonce(ephemeralPublicKey, maxEpoch, randomness);

    return {
      nonce,
      randomness,
      maxEpoch,
    };
  }

  const res = await fetch(`${CONFIG.sui.enoki.url}/zklogin/nonce`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${CONFIG.sui.enoki.key}`,
    },
    body: JSON.stringify({
      network: utils.getChainType(request.network),
      ephemeralPublicKey: getExtendedEphemeralPublicKey(ephemeralPublicKey),
    }),
  });
  const { data } = (await res.json()) as { data: IGetNonce };
  return data;
};
