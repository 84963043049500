import { getBalance as aptos } from './aptos/getBalance';
import { getBalance as sui } from './sui/getBalance';

import type { IReqGetBalance, ITokenInfo } from './types';

export const getBalance = async (
  request: IReqGetBalance,
): Promise<ITokenInfo[]> => {
  switch (request.network) {
    case 'sui:mainnet':
    case 'sui:devnet':
    case 'sui:testnet':
      return sui(request);
    case 'aptos:mainnet':
    case 'aptos:devnet':
    case 'aptos:testnet':
      return aptos(request);
    default:
      break;
  }
  throw new Error('not support network');
};
