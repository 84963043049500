import {
  Deserializer,
  Ed25519PrivateKey,
  EphemeralKeyPair,
  generateSignedTransaction,
  RawTransaction,
} from '@aptos-labs/ts-sdk';

import type { IReqTxSign, IResTxSign } from '../types';
import type { Aptos } from '@aptos-labs/ts-sdk';

export const txSign = async (request: IReqTxSign): Promise<IResTxSign> => {
  try {
    if (request.privateKey && request.proof) {
      const ephemeralKeyPair = new EphemeralKeyPair({
        privateKey: new Ed25519PrivateKey(request.privateKey),
        blinder: Buffer.from(request.proof, 'base64'),
        expiryDateSecs: BigInt(request.expiration),
      });
      const keylessAccount = await (
        global._CLIENT as Aptos
      ).deriveKeylessAccount({
        jwt: request.jwt,
        ephemeralKeyPair,
      });
      const senderAuthenticator = keylessAccount.signWithAuthenticator({
        rawTransaction: RawTransaction.deserialize(
          new Deserializer(Buffer.from(request.unsignedTx, 'base64')),
        ),
      });
      const signedTx = generateSignedTransaction({
        transaction: {
          rawTransaction: RawTransaction.deserialize(
            new Deserializer(Buffer.from(request.unsignedTx, 'base64')),
          ),
        },
        senderAuthenticator,
      });
      return {
        unsignedTx: request.unsignedTx,
        signature: Buffer.from(signedTx).toString('base64'),
      };
    }
    throw new Error('keystore error');
  } catch (error) {
    throw new Error(`${error}`);
  }
};
