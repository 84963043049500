import { AptosApiType, AptosConfig, MimeType } from '@aptos-labs/ts-sdk';
import { enqueueSnackbar } from 'notistack';

import { getNetworkConfig } from './utils/getNetworkConfig';
import { useUpdater } from '../../updater';

import type { IReqTxSend } from '../types';
import type { Aptos } from '@aptos-labs/ts-sdk';

export const txSend = (request: IReqTxSend): void => {
  try {
    const aptosConfig = new AptosConfig({
      network: getNetworkConfig(request.network),
    });
    const url = aptosConfig.getRequestUrl(AptosApiType.FULLNODE);
    fetch(`${url}/transactions`, {
      method: 'POST',
      headers: {
        'Content-Type': MimeType.BCS_SIGNED_TRANSACTION,
      },
      body: Buffer.from(request.signature, 'base64'),
    })
      .then((res) => {
        res
          .json()
          .then(({ hash, message }) => {
            if (hash) {
              (global._CLIENT as Aptos)
                .waitForTransaction({
                  transactionHash: hash,
                })
                .then(({ hash }) => {
                  useUpdater.setCount(useUpdater.count + 1);
                  enqueueSnackbar(`${hash}`, {
                    variant: 'success',
                  });
                })
                .catch((error) => {
                  enqueueSnackbar(`${error}`, {
                    variant: 'error',
                  });
                });
            } else {
              enqueueSnackbar(`${message}`, {
                variant: 'error',
              });
            }
          })
          .catch((error) => {
            enqueueSnackbar(`${error}`, {
              variant: 'error',
            });
          });
      })
      .catch((error) => {
        enqueueSnackbar(`${error}`, {
          variant: 'error',
        });
      });
  } catch (error) {
    throw new Error(`${error}`);
  }
};
