import { SuiClient } from '@mysten/sui.js/client';

import { getProviderUrl } from './utils/getProviderUrl';

import type { NETWORK } from '../types';

export const setClient = (network: NETWORK): void => {
  const url = getProviderUrl(network);
  const client = new SuiClient({ url });
  global._CLIENT = client;
};
