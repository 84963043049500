import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { Auth } from './pages/auth';
import { Provider } from './pages/provider';
import { Root } from './pages/root';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
  },
  {
    path: '/provider',
    element: <Provider />,
  },
  {
    path: '/auth',
    element: <Auth />,
  },
]);

function App() {
  return (
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  );
}

export default App;
