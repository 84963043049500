import { txSend as aptos } from './aptos/txSend';
import { txSend as sui } from './sui/txSend';

import type { IReqTxSend } from './types';

export const txSend = (request: IReqTxSend): void => {
  switch (request.network) {
    case 'sui:mainnet':
    case 'sui:devnet':
    case 'sui:testnet':
      return sui(request);
    case 'aptos:mainnet':
    case 'aptos:devnet':
    case 'aptos:testnet':
      return aptos(request);
    default:
      break;
  }
  throw new Error('not support network');
};
