import { getNftList as aptos } from './aptos/getNftList';
import { getNftList as sui } from './sui/getNftList';

import type { IReqNftList, IResNftList } from './types';

export const getNftList = async (
  request: IReqNftList,
): Promise<IResNftList> => {
  switch (request.network) {
    case 'sui:mainnet':
    case 'sui:devnet':
    case 'sui:testnet':
      return sui(request);
    case 'aptos:mainnet':
    case 'aptos:devnet':
    case 'aptos:testnet':
      return aptos(request);
    default:
      break;
  }
  throw new Error('not support network');
};
