import { atom } from 'recoil';

import type { CRYPTO, NETWORK, PROVIDER } from '../provider/zktx/types';

export interface INonce {
  crypto: CRYPTO;
  expiration: number;
  publicKey: string;
  randomness: string;
  network: NETWORK;
  provider: PROVIDER;
}

export interface IAccount {
  email: string;
  picture: string;
  nonce: INonce;
  zkAddress: {
    address: string;
    proof: string;
    salt: string;
    jwt: string;
  };
}

export interface IAccounts {
  selected?: IAccount;
  accounts: { [key: string]: IAccount };
}

export const accountState = atom<IAccounts>({
  key: 'accounts',
  default: {
    accounts: {},
  },
});
