import { txSign as aptos } from './aptos/txSign';
import { txSign as sui } from './sui/txSign';

import type { IReqTxSign, IResTxSign } from './types';

export const txSign = async (request: IReqTxSign): Promise<IResTxSign> => {
  switch (request.network) {
    case 'sui:mainnet':
    case 'sui:devnet':
    case 'sui:testnet':
      return sui(request);
    case 'aptos:mainnet':
    case 'aptos:devnet':
    case 'aptos:testnet':
      return aptos(request);
    default:
      break;
  }
  throw new Error('not support network');
};
