import { Ed25519PrivateKey, EphemeralKeyPair } from '@aptos-labs/ts-sdk';

import type { IReqMakeOAuthUrl, IResMakeOAuthUrl } from '../types';

export const makeOAuthURL = async (
  request: IReqMakeOAuthUrl,
): Promise<IResMakeOAuthUrl> => {
  const privateKey = new Ed25519PrivateKey(
    Buffer.from(request.privateKey, 'base64'),
  );
  const {
    expiryDateSecs: expiration,
    nonce,
    blinder,
  } = new EphemeralKeyPair({
    privateKey,
    expiryDateSecs: BigInt(
      parseInt((new Date().getTime() / 1000).toString()) + request.duration,
    ),
  });

  switch (request.provider) {
    case 'google':
      return {
        url: `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${request.clientId}&response_type=id_token&redirect_uri=${request.redirectUrl}&scope=openid email profile&nonce=${nonce}`,
        randomness: Buffer.from(blinder).toString('base64'),
        network: request.network,
        expiration: Number(expiration),
        crypto: 'ed25519',
        publicKey: request.publicKey as string,
      };
    /*
        case 'facebook':
            break;
        case 'twitch':
            break;
        case 'kakao':
            break;
        case 'apple':
            break;
        case 'slack':
            break;
    */
    default:
      break;
  }
  throw new Error(`not support provider (${request.provider})`);
};
