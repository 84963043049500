import { convertChainName } from './convert';

import type { NETWORK } from '../../types';
import type { ChainContext, Wormhole } from '@wormhole-foundation/sdk';

export const getChainContext = (
  wh: Wormhole<'Mainnet' | 'Testnet' | 'Devnet'>,
  network: NETWORK,
): ChainContext<any, any> => {
  switch (network) {
    case 'aptos:mainnet':
      return wh.getChain(convertChainName(network)) as ChainContext<
        'Mainnet',
        'Aptos'
      >;
    case 'aptos:testnet':
      return wh.getChain(convertChainName(network)) as ChainContext<
        'Testnet',
        'Aptos'
      >;
    case 'aptos:devnet':
      return wh.getChain(convertChainName(network)) as ChainContext<
        'Devnet',
        'Aptos'
      >;
    case 'sui:mainnet':
      return wh.getChain(convertChainName(network)) as ChainContext<
        'Mainnet',
        'Sui'
      >;
    case 'sui:testnet':
      return wh.getChain(convertChainName(network)) as ChainContext<
        'Testnet',
        'Sui'
      >;
    case 'sui:devnet':
      return wh.getChain(convertChainName(network)) as ChainContext<
        'Devnet',
        'Sui'
      >;
    default:
      break;
  }
  throw new Error(`${network} is not supported`);
};
