import { decodeJwt } from 'jose';

import { getZkProof } from './utils/getZkProof';

import type { IAccount } from '../../../recoil';
import type { IReqMakeZkProof } from '../types';

export const makeZkProof = async (
  request: IReqMakeZkProof,
): Promise<IAccount> => {
  const { email, picture } = decodeJwt(request.jwt) as {
    email: string;
    picture: string;
  };

  const { proof, address } = await getZkProof(request);

  return {
    email,
    picture,
    nonce: request.nonce,
    zkAddress: {
      address,
      jwt: request.jwt,
      salt: request.salt,
      proof,
    },
  };
};
