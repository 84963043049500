import { setClient as aptos } from './aptos/setClient';
import { setClient as sui } from './sui/setClient';

import type { NETWORK } from './types';

export const setClient = (network: NETWORK): void => {
  switch (network) {
    case 'sui:mainnet':
    case 'sui:devnet':
    case 'sui:testnet':
      return sui(network);
    case 'aptos:mainnet':
    case 'aptos:devnet':
    case 'aptos:testnet':
      return aptos(network);
    default:
      break;
  }
  throw new Error('not support network');
};
