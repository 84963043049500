import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { wormhole } from '@wormhole-foundation/sdk';
import aptos from '@wormhole-foundation/sdk/aptos';
import sui from '@wormhole-foundation/sdk/sui';

import { getBalance } from './getBalance';
import { getNftList } from './getNftsList';
import { makeOAuthURL } from './makeOAuthURL';
import { makeZkProof } from './makeZkProof';
import { setClient } from './setClient';
import { txBuildTokenSend } from './txBuildTokenSend';
import { txSend } from './txSend';
import { txSign } from './txSign';
import { isEnabled } from './wormhole/isEnabled';
import { txBuildTokenRedeem } from './wormhole/txBuildTokenRedeem';
import { txBuildTokenTransfer } from './wormhole/txBuildTokenTransfer';

import type {
  IReqGetBalance,
  IReqMakeOAuthUrl,
  IReqMakeZkProof,
  IReqNftList,
  IReqTxBuildTokenSend,
  IReqTxSend,
  IReqTxSign,
  IReqWormholeRedeemTx,
  IReqWormholeTransferTx,
  IResMakeOAuthUrl,
  IResNftList,
  IResTxSign,
  ITokenInfo,
  NETWORK,
} from './types';
import type { IAccount } from '../../recoil';
import type { Wormhole } from '@wormhole-foundation/sdk';

export const ZkTxContext = createContext({
  zk: {
    makeOAuthURL: async (
      request: IReqMakeOAuthUrl,
    ): Promise<IResMakeOAuthUrl> => {
      throw new Error('zk.getOAuthURL is not supported');
    },
    makeZkProof: async (request: IReqMakeZkProof): Promise<IAccount> => {
      throw new Error('zk.generateZkProof is not supported');
    },
  },
  wormhome: {
    isEnabled: (network: NETWORK): boolean => {
      throw new Error('wormhome.isEnabled is not supported');
    },
    txBuildTokenTransfer: async (
      req: IReqWormholeTransferTx,
    ): Promise<string> => {
      throw new Error('wormhome.txBuildTokenTransfer is not supported');
    },
    txBuildTokenRedeem: async (req: IReqWormholeRedeemTx): Promise<string> => {
      throw new Error('wormhome.txBuildTokenRedeem is not supported');
    },
  },
  wallet: {
    setClient: (network: NETWORK): void => {
      throw new Error('wallet.setClient is not supported');
    },
    getBalance: async (request: IReqGetBalance): Promise<ITokenInfo[]> => {
      throw new Error('wallet.getBalance is not supported');
    },
    getNftList: async (request: IReqNftList): Promise<IResNftList> => {
      throw new Error('wallet.getBalance is not supported');
    },
    txBuildTokenSend: (request: IReqTxBuildTokenSend): Promise<string> => {
      throw new Error('wallet.sendToken is not supported');
    },
    txSign: (request: IReqTxSign): Promise<IResTxSign> => {
      throw new Error('wallet.txSign is not supported');
    },
    txSend: (request: IReqTxSend): void => {
      throw new Error('wallet.txSign is not supported');
    },
  },
});

export const ZkTxProvider = ({ children }: { children: ReactNode }) => {
  const initialized = useRef<boolean>(false);
  const [wh, setWh] = useState<
    Wormhole<'Mainnet' | 'Testnet' | 'Devnet'> | undefined
  >(undefined);

  useEffect(() => {
    const init = async () => {
      initialized.current = true;
      const temp = await wormhole('Testnet', [aptos, sui]);
      setWh(temp);
    };
    !initialized.current && init();
  }, []);

  return (
    <ZkTxContext.Provider
      value={{
        zk: {
          makeOAuthURL,
          makeZkProof,
        },
        wormhome: {
          isEnabled,
          txBuildTokenTransfer: (req: IReqWormholeTransferTx) => {
            if (wh) {
              return txBuildTokenTransfer(wh, req);
            }
            throw new Error('wormhome.txBuildTokenTransfer is not supported');
          },
          txBuildTokenRedeem: (req: IReqWormholeRedeemTx) => {
            if (wh) {
              return txBuildTokenRedeem(wh, req);
            }
            throw new Error('wormhome.txBuildTokenRedeem is not supported');
          },
        },
        wallet: {
          setClient,
          getBalance,
          getNftList,
          txBuildTokenSend,
          txSign,
          txSend,
        },
      }}
    >
      {children}
    </ZkTxContext.Provider>
  );
};

export const useProviderZktx = () => {
  return useContext(ZkTxContext);
};
