import { getNonce } from './utils/getNonce';

import type { IReqMakeOAuthUrl, IResMakeOAuthUrl } from '../types';

export const makeOAuthURL = async (
  request: IReqMakeOAuthUrl,
): Promise<IResMakeOAuthUrl> => {
  try {
    const { nonce, randomness, maxEpoch } = await getNonce(request);

    switch (request.provider) {
      case 'google':
        return {
          url: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${request.clientId}&response_type=id_token&redirect_uri=${request.redirectUrl}&scope=openid email profile&nonce=${nonce}`,
          randomness,
          network: request.network,
          expiration: maxEpoch,
          crypto: 'ed25519',
          publicKey: request.publicKey as string,
        };
      /*
          case 'facebook':
              break;
          case 'twitch':
              break;
          case 'kakao':
              break;
          case 'apple':
              break;
          case 'slack':
              break;
      */
      default:
        break;
    }
    throw new Error(`not support provider (${request.provider})`);
  } catch (error) {
    throw new Error(`${error}`);
  }
};
