import { useEffect, useState } from 'react';

import { Box, ImageList, ImageListItem, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';

import { useProviderZktx } from '../provider/zktx';
import { accountState } from '../recoil';

import type { INftData } from '../provider/zktx/types';

export const ListNfts = () => {
  const api = useProviderZktx();
  const [{ selected: account }] = useRecoilState(accountState);

  const [nfts, setNfts] = useState<INftData[]>([]);

  useEffect(() => {
    const init = async () => {
      if (account) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { list, nextPage } = await api.wallet.getNftList({
          network: account.nonce.network,
          address: account.zkAddress.address,
        });
        setNfts(list);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <>
      {nfts.length === 0 && (
        <Box
          sx={(theme) => {
            return {
              display: 'flex',
              width: '100%',
              height: '100%',
              minHeight: { xs: '120px', sm: '160px' },
              borderRadius: '20px',
              borderStyle: 'dashed solid',
              borderColor: theme.palette.divider,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            };
          }}
        >
          <Typography variant="body2" color="secondary">
            No NFTs
          </Typography>
        </Box>
      )}
      {nfts.length > 0 && (
        <ImageList cols={4}>
          {nfts.map((item) => (
            <ImageListItem key={item.img}>
              <img src={item.img} alt={item.title} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </>
  );
};
