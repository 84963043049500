import { Ed25519PrivateKey, EphemeralKeyPair } from '@aptos-labs/ts-sdk';

import type { IReqMakeZkProof } from '../../types';
import type { Aptos } from '@aptos-labs/ts-sdk';

export const getZkProof = async ({
  nonce,
  jwt,
  privateKey,
}: IReqMakeZkProof): Promise<{ proof: string; address: string }> => {
  if (privateKey) {
    const ephemeralKeyPair = new EphemeralKeyPair({
      privateKey: new Ed25519PrivateKey(Buffer.from(privateKey, 'base64')),
      blinder: Buffer.from(nonce.randomness, 'base64'),
      expiryDateSecs: BigInt(nonce.expiration),
    });
    const keylessAccount = await (global._CLIENT as Aptos).deriveKeylessAccount(
      {
        jwt,
        ephemeralKeyPair,
      },
    );
    return {
      address: keylessAccount.accountAddress.toString(),
      proof: nonce.randomness,
    };
  }
  throw new Error('getZkProof error');
};
