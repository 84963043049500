import { amount, UniversalAddress } from '@wormhole-foundation/sdk';

import { isEnabled } from './isEnabled';
import { serializeTx } from './serializeTx';
import { convertChainName } from './utils/convert';
import { getChainContext } from './utils/getChainContext';

import type { IReqWormholeTransferTx } from '../types';
import type { Wormhole } from '@wormhole-foundation/sdk';

export const txBuildTokenTransfer = async (
  wh: Wormhole<'Mainnet' | 'Testnet' | 'Devnet'>,
  req: IReqWormholeTransferTx,
): Promise<string> => {
  if (
    wh &&
    req.network !== req.token.network &&
    req.token.info.decimal &&
    isEnabled(req.network)
  ) {
    try {
      const snd = getChainContext(wh, req.network);
      const sndBridge = await snd.getTokenBridge();
      const txs = sndBridge.transfer(
        new UniversalAddress(req.address, 'hex'),
        {
          chain: convertChainName(req.token.network),
          address: new UniversalAddress(req.token.to, 'hex'),
        },
        req.token.info.type,
        amount.units(amount.parse(req.token.amount, req.token.info.decimal)),
      );
      return serializeTx(req.network, req.address, txs);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  throw new Error('target network is same');
};
