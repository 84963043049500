import { txBuildTokenSend as aptos } from './aptos/txBuildTokenSend';
import { txBuildTokenSend as sui } from './sui/txBuildTokenSend';

import type { IReqTxBuildTokenSend } from './types';

export const txBuildTokenSend = async (
  request: IReqTxBuildTokenSend,
): Promise<string> => {
  switch (request.network) {
    case 'sui:mainnet':
    case 'sui:devnet':
    case 'sui:testnet':
      return sui(request);
    case 'aptos:mainnet':
    case 'aptos:devnet':
    case 'aptos:testnet':
      return aptos(request);
    default:
      break;
  }
  throw new Error('not support network');
};
