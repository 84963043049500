import { useState } from 'react';

import SendIcon from '@mui/icons-material/Send';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';

import { DlgTokenSend } from './dlgTokenSend';
import { DlgWormholeTokenSnd } from './dlgWormholeTokenSnd';
import { DrawChain } from './drawChain';
import CONFIG from '../provider/config.json';
import { useProviderZktx } from '../provider/zktx';
import { utils } from '../provider/zktx/utils';

import type { CHAIN, ITokenInfo, NETWORK } from '../provider/zktx/types';

export const BtnSend = ({
  tokenInfo,
  network,
}: {
  tokenInfo: ITokenInfo;
  network: NETWORK;
}) => {
  const api = useProviderZktx();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
  const open = Boolean(anchorEl);

  const [openSnd, setOpenSnd] = useState<boolean>(false);
  const [openWormhole, setOpenWormhole] = useState<boolean>(false);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <>
      <IconButton
        sx={{
          height: { xs: 24, sm: 32 },
          width: { xs: 24, sm: 32 },
        }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <SendIcon
          sx={{
            height: { xs: 16, sm: 24 },
            width: { xs: 16, sm: 24 },
            transform: 'rotate(-25deg)',
          }}
        />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setOpenSnd(true);
            handleClose();
          }}
        >
          <DrawChain network={network} size={16} />
          <Typography marginLeft={1}>Send</Typography>
        </MenuItem>
        <MenuItem
          disabled={!api.wormhome.isEnabled(network)}
          onClick={() => {
            setOpenWormhole(true);
            handleClose();
          }}
        >
          <Box
            component="img"
            draggable="false"
            sx={{
              height: 16,
              width: 16,
            }}
            alt="WORMHOLE"
            src="/logo-wormhole.svg"
          />
          <Typography marginLeft={1}>Wormhole</Typography>
        </MenuItem>
      </Menu>
      <DlgTokenSend
        tokenInfo={tokenInfo}
        open={openSnd}
        handleClose={() => setOpenSnd(false)}
      />
      <DlgWormholeTokenSnd
        targets={(CONFIG.wormhole.chains as CHAIN[]).filter(
          (item) => item !== utils.getChainName(network),
        )}
        tokenInfo={tokenInfo}
        open={openWormhole}
        handleClose={() => setOpenWormhole(false)}
      />
    </>
  );
};
