import { Ed25519PublicKey } from '@mysten/sui.js/keypairs/ed25519';
import { getExtendedEphemeralPublicKey } from '@mysten/zklogin';
import { enqueueSnackbar } from 'notistack';

import { getProverUrl } from './getProverUrl';
import CONFIG from '../../../config.json';
import { utils } from '../../utils';

import type { IReqMakeZkProof } from '../../types';

export const getZkProof = async ({
  nonce,
  jwt,
  salt,
}: IReqMakeZkProof): Promise<string> => {
  try {
    if (nonce.network === 'sui:devnet') {
      const url = getProverUrl(nonce.network);
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jwt,
          extendedEphemeralPublicKey: getExtendedEphemeralPublicKey(
            new Ed25519PublicKey(utils.base2buffer(nonce.publicKey)),
          ),
          maxEpoch: nonce.expiration,
          jwtRandomness: nonce.randomness,
          salt: utils.hex2buffer(salt).toString('base64'),
          keyClaimName: 'sub',
        }),
      });

      const data = await res.json();

      if (!res.ok) {
        throw new Error(`${data.message}`);
      }
      return JSON.stringify(data);
    }

    const res = await fetch(`${CONFIG.sui.enoki.url}/zklogin/zkp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${CONFIG.sui.enoki.key}`,
        'zklogin-jwt': jwt,
      },
      body: JSON.stringify({
        network: utils.getChainType(nonce.network),
        randomness: nonce.randomness,
        maxEpoch: nonce.expiration,
        ephemeralPublicKey: getExtendedEphemeralPublicKey(
          new Ed25519PublicKey(utils.base2buffer(nonce.publicKey)),
        ),
      }),
    });

    const { data, errors } = await res.json();

    if (!data) {
      throw new Error(`${errors}`);
    }
    return JSON.stringify(data);
  } catch (error) {
    enqueueSnackbar(`${error}`, {
      variant: 'error',
    });
    throw new Error(`${error}`);
  }
};
