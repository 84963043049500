import type { ReactElement } from 'react';
import { createContext, useContext } from 'react';

import nacl, { sign as naclSign } from 'tweetnacl';

import { useProviderFS } from '../file';

import type { CRYPTO } from '../zktx/types';

interface KEY {
  crypto: CRYPTO;
  publicKey: string;
  privateKey: string;
}

export const KeyStoreContext = createContext({
  ed25519: {
    generateKey: async (): Promise<KEY> => {
      throw new Error('ed25519.generateKey is not supported');
    },
  },
  save: async (key: KEY, password: string): Promise<void> => {
    throw new Error('save is not supported');
  },
  load: async (path: string, password: string): Promise<Buffer> => {
    throw new Error('load is not supported');
  },
});

export const KeyStoreProvider = ({ children }: { children: ReactElement }) => {
  const fs = useProviderFS();

  return (
    <KeyStoreContext.Provider
      value={{
        ed25519: {
          generateKey: async (): Promise<KEY> => {
            const privateKey = nacl.randomBytes(32);
            const { publicKey } = naclSign.keyPair.fromSeed(privateKey);
            return {
              crypto: 'ed25519',
              publicKey: Buffer.from(publicKey).toString('base64'),
              privateKey: Buffer.from(privateKey).toString('base64'),
            };
          },
        },
        save: async (key: KEY, password: string): Promise<void> => {
          if (!fs) {
            throw new Error('save (fs) error');
          }
          await fs.encrypt(
            `${key.crypto}:${Buffer.from(key.publicKey, 'base64').toString('hex')}`, // base64 -> hex
            password,
            Buffer.from(key.privateKey, 'base64'),
          );
        },
        load: async (path: string, password: string): Promise<Buffer> => {
          if (!fs) {
            throw new Error('load (fs) error');
          }
          const privateKey = await fs?.decrypt(path, password);
          if (privateKey) {
            return privateKey;
          }
          throw new Error('decrypt error');
        },
      }}
    >
      {children}
    </KeyStoreContext.Provider>
  );
};

export const useProviderKeyStore = () => {
  return useContext(KeyStoreContext);
};
