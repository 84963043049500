import { enqueueSnackbar } from 'notistack';

import { useUpdater } from '../../updater';

import type { IReqTxSend } from '../types';
import type { SuiClient } from '@mysten/sui.js/dist/cjs/client';

export const txSend = (request: IReqTxSend): void => {
  try {
    (global._CLIENT as SuiClient)
      .executeTransactionBlock({
        transactionBlock: request.unsignedTx,
        signature: [request.signature],
      })
      .then(({ digest }) => {
        useUpdater.setCount(useUpdater.count + 1);
        enqueueSnackbar(`${digest}`, {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(`${error}`, {
          variant: 'error',
        });
      });
  } catch (error) {
    throw new Error(`${error}`);
  }
};
