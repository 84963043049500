import { useState } from 'react';

import Logout from '@mui/icons-material/Logout';
import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { useProviderFS } from '../provider/file';
import { accountState } from '../recoil';
export const AppAppBar = () => {
  const navigate = useNavigate();

  const fs = useProviderFS();
  const [{ selected: account }] = useRecoilState(accountState);
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);
  const open = Boolean(anchorEl);

  const handleLogout = async () => {
    if (fs) {
      const list = await fs?.readDir('/');
      const remove = list.map((item) => fs.rmFile(item));
      await Promise.all(remove);
      navigate('/provider');
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 2,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={() => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            borderRadius: '999px',
            bgcolor: 'rgba(0, 0, 0, 0.6)',
            backdropFilter: 'blur(24px)',
            maxHeight: 40,
            boxShadow:
              '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
          })}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              ml: { xs: '-12px', sm: '-18px' },
              px: 0,
            }}
          >
            <Box
              component="img"
              draggable="false"
              sx={{ height: { xs: 40, sm: 46 } }}
              alt="OpenID Crypto Wallet"
              src="/logo/zktx.svg"
            />
            <Stack marginLeft={1}>
              <Typography variant="h6" fontWeight={800}>
                zktx.io
              </Typography>
              <Typography variant="caption" color="secondary">
                OpenID Crypto Wallet
              </Typography>
            </Stack>
          </Box>

          <Box
            sx={{
              gap: 0.5,
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              mr: '-18px',
            }}
          >
            <Typography
              color="secondary"
              sx={{ display: { xs: 'none', sm: 'inline' } }}
            >
              {account?.email}
            </Typography>
            <IconButton
              size="small"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <Avatar
                sx={{ height: { xs: 40, sm: 46 }, width: { xs: 40, sm: 46 } }}
                src={account?.picture}
              />
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={() => setAnchorEl(undefined)}
            onClick={() => setAnchorEl(undefined)}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
