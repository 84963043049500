import { useContext, useEffect, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  alpha,
  Box,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import { useRecoilState } from 'recoil';

import { BtnSend } from './btnSend';
import { DlgWormholeTokenRcv } from './dlgWormholeTokenRcv';
import { DrawChain } from './drawChain';
import CONFIG from '../provider/config.json';
import { UpdaterContext } from '../provider/updater';
import { useProviderZktx } from '../provider/zktx';
import { utils } from '../provider/zktx/utils';
import { accountState } from '../recoil';

import type { CHAIN, ITokenInfo } from '../provider/zktx/types';

export const Wallet = () => {
  const api = useProviderZktx();
  const { count } = useContext(UpdaterContext);
  const [{ selected, accounts }] = useRecoilState(accountState);
  const [value, setValue] = useState<string>('');

  const [currency, setCurrenty] = useState<ITokenInfo>({
    name: '',
    symbol: '',
    type: '',
    value: '',
    fValue: '',
  });
  const [tokens, setTokens] = useState<ITokenInfo[]>([]);

  useEffect(() => {
    const update = async () => {
      if (selected) {
        const list = await api.wallet.getBalance({
          network: selected.nonce.network,
          address: selected.zkAddress.address,
        });

        const temp: ITokenInfo[] = [];
        list.forEach((item) => {
          if (
            item.type ===
            CONFIG[utils.getChainName(selected.nonce.network)].currentyType
          ) {
            setCurrenty(item);
          } else {
            temp.push(item);
          }
        });
        setTokens(temp);
      }
    };

    selected &&
      setValue(`${selected.nonce.network}:${selected.zkAddress.address}`);
    selected && update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, count]);

  return (
    <Stack spacing={2} width="100%">
      {!!selected && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Chip
            sx={{ height: 36, paddingLeft: 0.4 }}
            icon={<DrawChain network={selected.nonce.network} size={30} />}
            label={
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {utils.getChainType(selected.nonce.network).toUpperCase()}
              </Typography>
            }
            color={
              utils.getChainType(selected.nonce.network).toUpperCase() ===
              'mainnet'
                ? 'success'
                : 'info'
            }
          />

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DlgWormholeTokenRcv
              sources={(CONFIG.wormhole.chains as CHAIN[]).filter(
                (item) => item !== utils.getChainName(selected.nonce.network),
              )}
            />
            <TextField
              select
              size="small"
              sx={{
                width: { xs: '300px', sm: '340px', md: '360px' },
                marginLeft: 1,
              }}
              InputProps={{
                sx: { borderRadius: '20px' },
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="Copy">
                      <IconButton
                        size="small"
                        onClick={async () => {
                          await navigator.clipboard.writeText(
                            selected.zkAddress.address,
                          );
                        }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              value={value}
              onChange={(e) =>
                e.target.value !== value && setValue(e.target.value)
              }
            >
              {Object.keys(accounts).map((item) => (
                <MenuItem
                  value={item}
                  key={item}
                  sx={{
                    width: { xs: '300px', sm: '340px', md: '360px' },
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'center',
                        marginRight: 1,
                      }}
                    >
                      <DrawChain
                        network={accounts[item].nonce.network}
                        size={16}
                      />
                    </Box>
                    <Typography overflow="hidden" textOverflow="ellipsis">
                      {accounts[item].zkAddress.address}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
              <MenuItem disabled>Add Wallet</MenuItem>
            </TextField>
          </Box>
        </Box>
      )}
      <Grid container width="100%">
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          paddingRight={{ xs: 0, sm: 0, md: 1 }}
          paddingBottom={{ xs: 1, sm: 1, md: 0 }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
              width: '100%',
              height: '100%',
              minHeight: { xs: '120px', sm: '160px' },
              bgcolor: 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              borderRadius: '20px',
              boxShadow: `0 0 24px 12px ${alpha('#033363', 0.2)}`,
              padding: 3,
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h6">Balance</Typography>
              </Box>
              {currency.name && selected && (
                <Box sx={{ display: 'flex' }}>
                  <BtnSend
                    tokenInfo={currency}
                    network={selected.nonce.network}
                  />
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end',
                height: '100%',
              }}
            >
              <Typography
                variant="h2"
                textAlign="end"
                fontWeight={400}
                flexGrow={1}
              >
                {currency.fValue}
              </Typography>
              <Stack
                direction="column"
                justifyContent="space-around"
                alignItems="flex-end"
              >
                <Typography marginLeft={1} variant="caption" color="primary">
                  {currency.name}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          paddingLeft={{ xs: 0, sm: 0, md: 1 }}
          paddingTop={{ xs: 1, sm: 1, md: 0 }}
        >
          {tokens.length === 0 && (
            <Box
              sx={(theme) => {
                return {
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  minHeight: { xs: '120px', sm: '160px' },
                  borderRadius: '20px',
                  borderStyle: 'dashed solid',
                  borderColor: theme.palette.divider,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                };
              }}
            >
              <Typography variant="body2" color="secondary">
                No Tokens
              </Typography>
            </Box>
          )}
          {tokens.length > 0 && (
            <Box
              sx={{
                alignSelf: 'center',
                width: '100%',
                height: '100%',
                minHeight: { xs: '120px', sm: '160px' },
                bgcolor: 'rgba(0, 0, 0, 0.4)',
                backdropFilter: 'blur(24px)',
                borderRadius: '20px',
                boxShadow: `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                padding: 3,
              }}
            >
              <TableVirtuoso
                data={tokens}
                itemContent={(_, token) => (
                  <td>
                    <ListItem
                      dense
                      disablePadding
                      secondaryAction={
                        <>
                          {selected && (
                            <BtnSend
                              tokenInfo={token}
                              network={selected.nonce.network}
                            />
                          )}
                        </>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography color="primary" variant="h6">
                            {token.fValue}
                          </Typography>
                        }
                        secondary={
                          <Typography color="primary" variant="caption">
                            {token.name}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </td>
                )}
                components={{
                  Table: (props) => <table {...props} width="100%" />,
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};
