import { useState } from 'react';

import { CircularProgress, MenuItem, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { enqueueSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';

import CONFIG from '../provider/config.json';
import { useProviderKeyStore } from '../provider/keystore';
import { useProviderZktx } from '../provider/zktx';
import { accountState } from '../recoil';

import type { CHAIN, ITokenInfo } from '../provider/zktx/types';

export const DlgWormholeTokenSnd = ({
  targets,
  tokenInfo,
  open,
  handleClose,
}: {
  targets: CHAIN[];
  tokenInfo: ITokenInfo;
  open: boolean;
  handleClose: () => void;
}) => {
  const api = useProviderZktx();
  const keyStore = useProviderKeyStore();
  const [{ selected: account }] = useRecoilState(accountState);

  const [loading, setLoading] = useState<boolean>(false);
  const [target, setTarget] = useState<CHAIN>(targets[0]);
  const [to, setTo] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);
  const [password, setPassword] = useState<string>('');

  const handleSend = async () => {
    try {
      if (account && api.wormhome.isEnabled(account.nonce.network)) {
        setLoading(true);
        const unsignedTx = await api.wormhome.txBuildTokenTransfer({
          network: account.nonce.network,
          salt: account.zkAddress.salt,
          address: account.zkAddress.address,
          proof: account.zkAddress.proof,
          token: {
            amount: amount.toString(),
            info: tokenInfo,
            network:
              `${target}:${CONFIG.wormhole.network.toLowerCase()}` as any, // TODO: any...
            to,
          },
        });
        const privateKey = await keyStore.load(
          `${account.nonce.crypto}:${Buffer.from(account.nonce.publicKey, 'base64').toString('hex')}`, // base64 -> hex
          password,
        );
        const signedTx = await api.wallet.txSign({
          unsignedTx,
          network: account.nonce.network,
          salt: account.zkAddress.salt,
          address: account.zkAddress.address,
          privateKey,
          expiration: account.nonce.expiration,
          jwt: account.zkAddress.jwt,
          proof: account.zkAddress.proof,
        });
        if (signedTx.signature) {
          api.wallet.txSend({
            unsignedTx: signedTx.unsignedTx,
            network: account.nonce.network,
            salt: account.zkAddress.salt,
            address: account.zkAddress.address,
            signature: signedTx.signature,
          });
          handleClose();
        } else {
          enqueueSnackbar('sign transaction error', {
            variant: 'error',
          });
        }
      }
    } catch (error) {
      enqueueSnackbar(`${error}`, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={() => handleClose()}>
      <DialogTitle>{`Wormhole (${tokenInfo.name})`}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <TextField
            label="Target"
            select
            variant="standard"
            defaultValue={targets[0]}
            value={target}
            onChange={(e) => setTarget(e.target.value as CHAIN)}
          >
            {targets.map((item, key) => (
              <MenuItem value={item} key={key}>
                {item.toUpperCase()}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label={`Address (${target?.toUpperCase()})`}
            variant="standard"
            onChange={(e) => {
              setTo(e.target.value);
            }}
          />
          <TextField
            label="Amount"
            variant="standard"
            type="number"
            onChange={(e) => {
              setAmount(parseFloat(e.target.value));
            }}
          />
          <TextField
            label="Password"
            variant="standard"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          disabled={loading || !password || !to || !amount}
          onClick={handleSend}
          variant="contained"
          color="secondary"
        >
          {loading ? <CircularProgress size={21} /> : 'Send'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
