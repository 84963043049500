import { makeOAuthURL as aptos } from './aptos/makeOAuthURL';
import { makeOAuthURL as sui } from './sui/makeOAuthURL';

import type { IReqMakeOAuthUrl, IResMakeOAuthUrl } from './types';

export const makeOAuthURL = async (
  request: IReqMakeOAuthUrl,
): Promise<IResMakeOAuthUrl> => {
  switch (request.network) {
    case 'aptos:mainnet':
    case 'aptos:devnet':
    case 'aptos:testnet':
      return aptos(request);
    case 'sui:mainnet':
    case 'sui:devnet':
    case 'sui:testnet':
      return sui(request);
    default:
      break;
  }
  throw new Error('not support network');
};
