import type { ReactElement } from 'react';
import { useEffect, useRef, useState } from 'react';

import GoogleIcon from '@mui/icons-material/Google';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';

import { DlgSetPassword } from '../component/dlgSetPassword';
import CONFIG from '../provider/config.json';
import { useProviderFS } from '../provider/file';
import { useProviderKeyStore } from '../provider/keystore';
import { useProviderZktx } from '../provider/zktx';

import type { CHAIN, NETWORK, PROVIDER } from '../provider/zktx/types';
import type { INonce } from '../recoil';

export const Provider = () => {
  const initialized = useRef<boolean>(false);
  const navigate = useNavigate();

  const fs = useProviderFS();
  const keyStore = useProviderKeyStore();
  const api = useProviderZktx();
  const [loading, setLoading] = useState<boolean>(false);

  const [blockchain, setBlockChain] = useState<CHAIN | undefined>(undefined);
  const [provider, setProvider] = useState<PROVIDER | undefined>(undefined);
  const [dlgOpen, setDlgOpen] = useState<boolean>(false);

  const keyless: { icon: ReactElement; provider: PROVIDER }[] = [
    {
      icon: <GoogleIcon />,
      provider: 'google',
    },
  ];

  const zklogin: { icon: ReactElement; provider: PROVIDER }[] = [
    {
      icon: <GoogleIcon />,
      provider: 'google',
    },
  ];

  useEffect(() => {
    const init = async () => {
      if (fs) {
        initialized.current = true;
        const hasConfig = await fs.isExist('config.json');
        if (hasConfig) {
          navigate('/');
        }
      }
    };
    !initialized.current && init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fs]);

  return (
    <div className="App">
      <header className="App-header"></header>
      <Container maxWidth="sm">
        <Grid container sx={{ height: '100vh' }}>
          <Grid item xs={12} paddingBottom={8}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <Stack>
                <h1
                  style={{
                    fontSize: '28px',
                    fontWeight: 500,
                    fontFamily: 'Roboto',
                    color: '#c3cad8',
                  }}
                >
                  OpenID Crypto Wallet
                </h1>
                <TypeAnimation
                  sequence={['Hello, World!', 4000, 'Goodbye, Mnemonic.', 4000]}
                  cursor={true}
                  repeat={Infinity}
                  style={{
                    fontSize: '18px',
                    fontFamily: 'Roboto',
                    color: '#ffffff',
                    textAlign: 'center',
                  }}
                />
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Box
                  component="img"
                  draggable="false"
                  sx={{
                    height: 64,
                    width: 64,
                  }}
                  alt="APTOS Keyless Account"
                  src="/logo-aptos.svg"
                />
                <Typography variant="body2">Keyless Account</Typography>
                <Stack>
                  {/* aptos */}
                  {keyless.map((item, key) => (
                    <Button
                      key={key}
                      size="large"
                      variant="outlined"
                      startIcon={item.icon}
                      disabled={loading}
                      onClick={() => {
                        setBlockChain('aptos');
                        setProvider(item.provider);
                        setDlgOpen(true);
                      }}
                    >
                      {item.provider}
                    </Button>
                  ))}
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Box
                  component="img"
                  draggable="false"
                  sx={{
                    height: 64,
                    width: 64,
                  }}
                  alt="SUI zkLogin"
                  src="/logo-sui.svg"
                />
                <Typography variant="body2">zkLogin</Typography>
                <Stack>
                  {/* sui */}
                  {zklogin.map((item, key) => (
                    <Button
                      key={key}
                      size="large"
                      variant="outlined"
                      startIcon={item.icon}
                      disabled={loading}
                      onClick={() => {
                        setBlockChain('sui');
                        setProvider(item.provider);
                        setDlgOpen(true);
                      }}
                    >
                      {item.provider}
                    </Button>
                  ))}
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <DlgSetPassword
        open={dlgOpen}
        handleConfirm={async (password: string | undefined) => {
          try {
            if (fs && blockchain && provider && password) {
              setLoading(true);
              const key = await keyStore.ed25519.generateKey();
              const network = CONFIG[blockchain as CHAIN].network as NETWORK;
              api.wallet.setClient(network);
              const { url, ...etc } = await api.zk.makeOAuthURL({
                provider,
                clientId: CONFIG.clientId,
                redirectUrl: CONFIG.redirect,
                network,
                duration: CONFIG[blockchain as CHAIN].duration,
                privateKey: key.privateKey,
                publicKey: key.publicKey,
              });
              await keyStore.save(key, password);
              await fs.writeFile(
                'nonce.json',
                JSON.stringify({ ...etc, provider } as INonce),
              );
              window.location.replace(url);
            }
            setProvider(undefined);
            setDlgOpen(false);
          } catch (error) {
            enqueueSnackbar(`${error}`, {
              variant: 'error',
            });
            setLoading(false);
          }
        }}
      />
    </div>
  );
};
