import { useEffect, useRef, useState } from 'react';

import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import { DlgPassword } from '../component/dlgPassword';
import { useProviderFS } from '../provider/file';
import { useProviderKeyStore } from '../provider/keystore';
import { useProviderZktx } from '../provider/zktx';
import { utils } from '../provider/zktx/utils';

import type { INonce } from '../recoil';

export const Auth = () => {
  const initialized = useRef<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const fs = useProviderFS();
  const keyStore = useProviderKeyStore();
  const api = useProviderZktx();

  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<{ nonce: INonce; jwt: string } | undefined>(
    undefined,
  );

  const makeZkProof = async (
    nonce: INonce,
    jwt: string,
    privateKey?: string,
  ): Promise<void> => {
    const account = await api.zk.makeZkProof({
      nonce,
      jwt,
      privateKey,
      salt: utils.str2Hash(utils.getZkPath(nonce.network, 0), 16),
    });
    const fileName = `${account.nonce.network}:${account.zkAddress.address}`;
    await fs?.writeFile(fileName, JSON.stringify(account));
    await fs?.writeFile(
      'config.json',
      JSON.stringify({
        selected: fileName,
      }),
    );
    await fs?.rmFile('nonce.json');
    navigate('/');
  };

  const handleConfirm = async (password: string | undefined) => {
    if (password && data) {
      const privateKey = await keyStore.load(
        `${data.nonce.crypto}:${Buffer.from(data.nonce.publicKey, 'base64').toString('hex')}`, // base64 -> hex
        password,
      );
      setOpen(false);
      api.wallet.setClient(data.nonce.network);
      await makeZkProof(data.nonce, data.jwt, privateKey.toString('base64'));
    }
  };

  useEffect(() => {
    const init = async () => {
      if (fs) {
        initialized.current = true;
        const hasConfig = await fs.isExist('config.json');
        if (hasConfig) {
          navigate('/');
        }
        const hasNonce = await fs.isExist('nonce.json');
        if (!hasNonce) {
          navigate('/provider');
          return;
        }

        const { id_token: jwt } = queryString.parse(location.hash) as {
          id_token: string;
        };

        if (!jwt) {
          navigate('/provider');
          return;
        }
        const nonce = JSON.parse(
          Buffer.from(await fs.readFile('nonce.json')).toString('utf8'),
        ) as INonce;

        const hasKeyStore = await fs.isExist(
          `${nonce.crypto}:${Buffer.from(nonce.publicKey, 'base64').toString('hex')}`, // base64 -> hex
        );

        if (!hasKeyStore) {
          navigate('/provider');
          return;
        }

        if (utils.getChainName(nonce.network) === 'sui') {
          await makeZkProof(nonce, jwt);
        } else {
          setData({ nonce, jwt });
          setOpen(true);
        }
      }
    };
    setOpen(false);
    !initialized.current && init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fs, location.hash]);

  return (
    <div className="App">
      <header className="App-header"></header>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Stack
          spacing={1}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>Generating Wallet</Typography>
          <Box sx={{ width: '200px' }}>
            <LinearProgress />
          </Box>
        </Stack>
      </Box>
      <DlgPassword open={open} handleConfirm={handleConfirm} />
    </div>
  );
};
