import { utils } from '../../utils';

import type { NETWORK } from '../../types';

export const convertChainName = (network: NETWORK): 'Aptos' | 'Sui' => {
  const chain = utils.getChainName(network);
  switch (chain) {
    case 'sui':
      return 'Sui';
    case 'aptos':
      return 'Aptos';
    default:
      break;
  }
  throw new Error(`${chain} is not support chain`);
};
